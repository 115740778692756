import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import CheckboxInput from '@/components/form/CheckboxInput';
import TextInput from '@/components/form/TextInput';
import { useIsMobile } from '@/lib';
import { useFormik } from 'formik';
import { Link, navigate, PageProps } from 'gatsby';
import * as React from 'react';
import { FC, useState } from 'react';
import { useBoolean } from 'react-use';
import * as Yup from 'yup';

const HostLandingPage: FC<PageProps> = () => {
  const isMobile = useIsMobile();
  const [success, setSuccess] = useBoolean(false);
  const [error, setError] = useState<string>();

  const formik = useFormik({
    initialValues: {
      companyName: '',
      companyType: '',
      name: '',
      jobTitle: '',
      email: '',
      phoneNumber: '',
      toc: false,
      logoCheck: false,
      interviewCheck: false,
    },
    validationSchema: Yup.object({
      companyName: Yup.string().required("Please provide your company's name"),
      companyType: Yup.string().required('Please select your company type'),
      name: Yup.string().required('Please provide your name'),
      jobTitle: Yup.string().required('Please provide your job title'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Please provide your email address'),
      phoneNumber: Yup.string().required('Please provide your phone number'),
      toc: Yup.boolean()
        .isTrue('You must accept T&C')
        .required('You must accept T&C'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSuccess(false);
      setError(undefined);
      setSubmitting(true);

      try {
        const formData = new URLSearchParams();

        formData.append('custom_company', values.companyName);
        formData.append('custom_company_type', values.companyType);
        formData.append('name', values.name);
        formData.append('custom_job_title', values.jobTitle);
        formData.append('email', values.email);
        formData.append('custom_phone', values.phoneNumber);

        formData.append('custom_consent_logo', `${values.logoCheck}`);
        formData.append(
          'custom_consent_user_interview',
          `${values.interviewCheck}`
        );

        formData.append('start_day', '0'); // set autoresponder to 0
        formData.append('campaign_token', 'zho5g'); // OptiOffer Host Milano
        formData.append('webform[consent#honK-ver#zqHo]', `${values.toc}`); // T&C consent field

        await fetch('https://app.getresponse.com/add_subscriber.html', {
          mode: 'no-cors',
          method: 'POST',
          body: formData,
        });

        setSuccess(true);

        setTimeout(() => {
          navigate('/');
        }, 2000);
      } catch (ex) {
        setError(ex?.toString());
      }

      setSubmitting(false);
    },
  });

  return (
    <Layout className="bg-lightPeach relative">
      <SEO
        url="host"
        title="The Ho.Re.Ca. Sales Revolution is Here!"
        description=""
        disableIndex
      />

      <section
        id="join-the-revolution"
        className="min-h-screen w-screen flex justify-center items-center px-4 py-10"
      >
        <form
          onSubmit={formik.handleSubmit}
          className="relative flex flex-col width-full max-w-5xl text-lg"
        >
          <h2 className="font-semibold text-3xl text-center text-primary mb-16">
            All you have to do is fill this form. We’ll take it from there.
          </h2>
          <div className="font-light text-black rounded-lg lg:p-12">
            <TextInput
              formik={formik}
              name="companyName"
              label="Company Name"
              placeholder="eg: Company LLc"
              className="mb-4"
            />
            <TextInput
              formik={formik}
              name="companyType"
              label="Company Type"
              placeholder="eg: Supplier/Reseller"
              className="mb-4"
            />
            <TextInput
              formik={formik}
              name="name"
              label="Name"
              placeholder="eg: Melon Musk"
              className="mb-4"
            />
            <TextInput
              formik={formik}
              name="jobTitle"
              label="Job Title"
              placeholder="eg: Sales Director"
              className="mb-4"
            />
            <TextInput
              formik={formik}
              name="email"
              label="Email"
              placeholder="eg: melon@spaceY.com"
              className="mb-4"
            />
            <TextInput
              formik={formik}
              name="phoneNumber"
              label="Phone Number"
              placeholder="eg: +39 312 3456789"
              className="mb-4"
            />

            <CheckboxInput formik={formik} name="toc">
              I accept the
              <Link to="/terms-and-conditions/" className="ml-1 underline">
                Terms & Conditions
              </Link>
            </CheckboxInput>
            <CheckboxInput formik={formik} name="logoCheck">
              I want my company <strong>logo</strong> on the optioffer.com
              website
            </CheckboxInput>
            <CheckboxInput formik={formik} name="interviewCheck">
              I want to be <strong>contacted</strong> by OptiOffer for{' '}
              <strong>User Interviews</strong>
            </CheckboxInput>

            <div className="flex flex-col items-center justify-center mt-8">
              {error && (
                <div id="OptInError" className="text-error mb-6">
                  {error}
                </div>
              )}

              {success ? (
                <div id="OptInSuccess" className="text-primary">
                  Great! We&apos;ll contact you soon!
                </div>
              ) : (
                <button
                  type="submit"
                  className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-primary-200"
                  disabled={formik.isSubmitting}
                >
                  Join the Sales Revolution!
                </button>
              )}
            </div>
          </div>
        </form>
      </section>

      <section className="w-screen flex flex-col items-center bg-primary text-white pt-12 pb-20 px-4 relative z-10">
        <div className="relative flex flex-col w-full flex-1 max-w-5xl justify-around">
          <div className="font-semibold text-4xl lg:text-6xl text-center mb-20 self-center">
            The Ho.Re.Ca. {!isMobile && <br />}Sales Revolution is Here!
          </div>
          <div className="font-light text-xl lg:px-28">
            <p className="mb-8">
              How many hours do you spend weekly on repetitive tasks like:
              <ul className="disc-list">
                <li>Sending updated price lists and catalogs</li>
                <li>Managing quotes, orders, and payments</li>
              </ul>
            </p>
            <p className="mb-8">
              If you could use all that time talking to customers, how would{' '}
              <strong className="font-semibold">that</strong> affect your bottom
              line?
            </p>

            <p className="mb-8">
              No more delayed customer communication, lost revenue, and missed
              opportunities.
            </p>

            <p className="mb-8 font-semibold">
              Sales should be about building authentic relationships with
              customers, staying aware of opportunities, and not repetitive
              formalities that hold you back.
            </p>
            <p className="mb-8">
              Together we are going to build the best solution to fix these
              challenges.
            </p>
          </div>
        </div>
      </section>

      <section className="w-screen flex flex-col items-center pt-12 pb-20 px-4 relative z-10">
        <div className="relative flex flex-col w-full flex-1 max-w-5xl justify-around">
          <div className="text-primary font-semibold text-4xl lg:text-6xl text-center mb-20 self-center">
            Let’s build the future of {!isMobile && <br />}Ho.Re.Ca. sales
            together!
          </div>
          <div className="text-gray-400 text-xl font-light lg:px-28">
            <ul className="disc-list mb-8">
              <li>Help your company reach new heights</li>
              <li>Don’t let your competition get a head start</li>
              <li>
                Get early access to this revolutionary technology, don’t wait
                until everybody uses it
              </li>
            </ul>
            <p className="mb-8">
              Our vision is a platform that links resellers with suppliers,
              making selling & buying commercial goods easy, efficient and
              mobile. A B2C experience in B2B transactions.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default HostLandingPage;
